import React from 'react';
import './App.css';
import bild1 from './bild_1.jpg';  // Bild importieren
import bild2 from'./bild_2.jpg';
import bild3 from './bild_3.jpg'
import impressumText from './impressum';
import datenschutzText from './datenschutz';
import widerrufsrechtText from './widerrufsrecht'
import ContactForm from './ContactForm';

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="background-image">
          <button className="button" onClick={() => openContactModal()}>Bestellen</button> {/* Ändern der onClick-Funktion */}
          <button className="button" onClick={() => openVideoModal()}>Video</button>
        </div>
      </header>
      <main className="content">
        <div className="separator"></div>
        <div className="headline">
          <h1>Strahlenschutztasche für Ihr Smartphone</h1>
        </div>

        <section className="section">
          <div className="text">
            <div className="icon-header">
              <img src={require('./icon_signal.png')} alt="Icon Strahlung" className="icon" />
              <h3>Hochentwickelte Strahlenschutztasche</h3>
            </div>
            <p>
              Unsere Strahlenschutztasche basiert auf dem Prinzip des <strong>Faraday-Käfigs</strong> und ist mit einem speziellen, hochleitfähigen Stoff ausgekleidet. Sobald Sie Ihr Smartphone oder ein anderes elektronisches Gerät in diese Tasche legen, werden alle eingehenden und ausgehenden Funkwellen blockiert. Dies umfasst <strong>Mobilfunkstrahlen (2G, 3G, 4G, 5G), WLAN, Bluetooth, GPS sowie RFID und NFC</strong>. Die Tasche unterbricht effektiv jegliche Kommunikation des Gerätes mit der Außenwelt, sodass dieses vollständig abgeschirmt ist.
            </p>
          </div>
          <div className="image">
            <img src={bild1} alt="Schutz vor negativer Energie" />  {/* Bild verwenden */}
          </div>
        </section>

        <div className="separator"></div>
        <section className="section">
          <div className="text">
            <div className="icon-header">
              <img src={require('./icon_phone.png')} alt="Icon Strahlung" className="icon" />
              <h3>Schutz vor Abhörmaßnahmen und Bespitzelung</h3>
            </div>
            <p>Unsere Strahlenschutztasche bietet effektiven Schutz gegen Abhör- und Überwachungsversuche, sei es durch <strong>Hacker oder staatliche Geheimdienste.</strong> Indem sämtliche Funkwellen blockiert werden, verhindert die Tasche, dass unerwünschter Zugriff auf Ihr Gerät möglich ist. Selbst fortschrittliche Überwachungstechniken, die von Geheimdiensten eingesetzt werden, können diese Schutzmaßnahme nicht überwinden. Gehen Sie kein Risiko ein und schützen Sie Ihre Privatsphäre noch heute.</p>
          </div>
          <div className="image">
            <img src={bild2} alt="Schutz vor negativer Energie" />  {/* Bild verwenden */}
          </div>
        </section>
        <div className="separator"></div>
        <section className="section">
          <div className="text">
            <div className="icon-header">
              <img src={require('./icon_strahlung.png')} alt="Icon Strahlung" className="icon" />
              <h3>Schutz vor Elektromagnetischer Strahlung</h3>
            </div>
            <p>Unsere Strahlenschutztasche bietet nicht nur Schutz vor Überwachung, sondern auch vor schädlicher elektromagnetischer Strahlung, die von Smartphones und anderen elektronischen Geräten ausgeht. Sobald Ihr Gerät in der Hülle verstaut ist, blockiert das spezielle Material effektiv Mobilfunkfrequenzen, einschließlich <strong>5G, WLAN, Bluetooth und anderen hochfrequente elektromagnetische Signale.</strong> Mit unserer Tasche minimieren Sie die Strahlenbelastung, die potenziell gesundheitsschädigend sein kann, und schaffen sich eine sicherere Umgebung.</p>
          </div>
          <div className="image">
            <img src={bild3} alt="Schutz vor negativer Energie" />  {/* Bild verwenden */}
          </div>
        </section>
        <div className="separator"></div>
        {/* Weitere Abschnitte */}
        {/* Hinzufügen des Kaufen-Buttons nach dem letzten Separator */}
        <div className="button-container">
          <button className="button" onClick={() => openContactModal()}>Bestellen</button>
        </div>

        {/* Hinzufügen eines weiteren Separators */}
        <div className="separator"></div>
      </main>
      {/* Video Modal */}
      <div id="videoModal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={() => closeVideoModal()}>&times;</span>
          <div className="video-container">
            <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/7cPC2Lxrdks"
              
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Produktvideo"
            ></iframe>
          </div>
        </div>
      </div>

      <div id="contactModal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={() => closeContactModal()}>&times;</span>
          <ContactForm />  {/* Hier wird das Kontaktformular eingefügt */}
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">
        <ul className="footer-links">
          <li><a href="#impressum" onClick={() => openModal('impressum')}>Impressum</a></li>
          <li><a href="#datenschutz" onClick={() => openModal('datenschutz')}>Datenschutzrichtlinie</a></li>
          <li><a href="#widerruf" onClick={() => openModal('widerruf')}>Widerrufsrecht</a></li>
        </ul>
      </footer>
      {/* Modal for Footer Links */}
      <div id="footerModal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={() => closeFooterModal()}>&times;</span>
          <div id="modalContent"></div>
        </div>
      </div>
    </div>
  );
}

function openVideoModal() {
  document.getElementById('videoModal').style.display = 'block';
}

function closeVideoModal() {
  const videoModal = document.getElementById('videoModal');
  const iframe = videoModal.querySelector('iframe');
  
  // Reset the src attribute to stop the video
  iframe.src = iframe.src;

  // Hide the modal
  videoModal.style.display = 'none';
}

function openContactModal() {
  document.getElementById('contactModal').style.display = 'block';
}

function closeContactModal() {
  document.getElementById('contactModal').style.display = 'none';
}

function openModal(type) {
  const modal = document.getElementById('footerModal');
  const content = document.getElementById('modalContent');
  
  if (type === 'impressum') {
    content.innerHTML = impressumText;
  } else if (type === 'datenschutz') {
    content.innerHTML = datenschutzText
  } else if (type === 'widerruf') {
    content.innerHTML = widerrufsrechtText
  }
  
  modal.style.display = 'block';
}

function closeFooterModal() {
  document.getElementById('footerModal').style.display = 'none';
}

export default App;
